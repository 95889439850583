import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { TopHeader, Section } from "../components/Section"
import Seo from "../components/Seo"

const ProjectWrapper = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  column-gap: 2.8rem;
  row-gap: 2rem;
  max-width: 80rem;
  margin: 5rem auto 3rem auto;
  padding: 0 1rem;
`

const Project = styled.div`
  display: flex;
  flex-direction: column;

  .title {
    font-size: 1.3rem;
    font-weight: 700;
    margin-top: 0.5rem;
    margin-bottom: 0;
    color: black;
  }

  .category {
    display: block;
    color: #00605e;
    font-weight: 700;
    font-size: 0.9rem;
    text-transform: uppercase;
  }

  p {
    color: #53575f;
  }

  .content {
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`

const BlogImage = styled(GatsbyImage)`
  border-radius: 5px;
  -webkit-border-radius: 5p;
  box-shadow: "0 25px 50px -12px rgba(0, 0, 0, 0.25)";
  filter: grayscale(100%);
`

const Pagination = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 45rem;
  list-style: none;
  margin: 1rem auto;
  padding: 1rem;

  .pageNumbers {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  a {
    margin: 1rem 0.5rem;
  }

  .pageLink {
    padding: 0.5rem 0.9rem;
    border: 2px solid #0d7d83;
    border-radius: 5px;
    font-weight: 600;
  }

  .pageLink:hover {
    background-color: #0d7d83;
    color: white;
  }

  .nextPrev {
    text-decoration: none;
    font-weight: 600;
    padding: 0.5rem 0.9rem;
    border: 2px solid #0d7d83;
    border-radius: 5px;

    &:hover {
      background-color: #0d7d83;
      color: white;
    }
  }
`

export default class ProjectsList extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges
    const { currentProjectPage, numProjectPages } = this.props.pageContext
    const isFirst = currentProjectPage === 1
    const isLast = currentProjectPage === numProjectPages
    const prevPage =
      currentProjectPage - 1 === 1 ? "." : (currentProjectPage - 1).toString()
    const nextPage = (currentProjectPage + 1).toString()

    return (
      <Layout>
        <Seo
          title="Projects"
          description="A collection of our projects over the years as we have served a noticeable number of commercial and residential customers."
        />
        <Section>
          <TopHeader>
            <h1>Our Projects</h1>
            <h2>
              Over the years, we have served a noticeable number of commercial
              and residential customers. We have developed expertise in
              Environmental Services, such as asbestos removal and drywall
              disposal as well as A&D Vancouver Demolition Projects and
              services.
            </h2>
          </TopHeader>
        </Section>
        <ProjectWrapper>
          {posts.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug
            const desc = node.excerpt
            const image = node.frontmatter.featuredImage
            const alt = node.frontmatter.imageAlt
            const category = node.frontmatter.category

            return (
              <Project key={node.frontmatter.slug}>
                <BlogImage
                  image={getImage(image)}
                  alt={alt}
                  objectFit="cover"
                  layout="fullWidth"
                  style={{
                    boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.15)",
                  }}
                />
                <div className="content">
                  <span className="category">{category}</span>
                  <h3 className="title">{title}</h3>
                  <p>{desc}</p>
                </div>
              </Project>
            )
          })}
        </ProjectWrapper>
        <Pagination>
          {!isFirst && (
            <Link to={"/projects/" + prevPage} rel="prev" className="nextPrev">
              Previous Page
            </Link>
          )}
          <div className="pageNumbers">
            {Array.from({ length: numProjectPages }, (_, i) => (
              <Link
                key={`pagination-number${i + 1}`}
                className="pageLink"
                to={`/projects/${i === 0 ? "" : i + 1}`}
                style={{
                  textDecoration: "none",
                  color: i + 1 === currentProjectPage ? "#ffffff" : "",
                  background: i + 1 === currentProjectPage ? "#0D7D83" : "",
                }}
              >
                {i + 1}
              </Link>
            ))}
          </div>
          {!isLast && (
            <Link to={"/projects/" + nextPage} rel="next" className="nextPrev">
              Next Page
            </Link>
          )}
        </Pagination>
      </Layout>
    )
  }
}

export const projectsListQuery = graphql`
  query projectsListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { contentType: { eq: "project" } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            slug
            title
            imageAlt
            category
            featuredImage {
              childImageSharp {
                gatsbyImageData(
                  width: 800
                  height: 500
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, PNG]
                )
              }
            }
          }
        }
      }
    }
  }
`
